<template>
  <div id="searResult" class="main-top" style="min-height: 100vh">
    <div class="search-bgc"></div>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
import search from "../../assets/css/search/search.css";
export default {
  data() {
    return {
      hotStand: 50,
      num: 0,
      array: [],
      lessArrays: [],
      isAdd: false,
      storeAll: [{ a: "b" }, { a: "b" }],
    };
  },
  components: {},
  created() {
    this.$store.state.headLogo = true;
  },
  methods: {
    add(id) {
      this.isAdd = !this.isAdd;
      // console.log(this.isAdd);
    },
  },
  computed: {
    hot: function () {
      let f = this.hotStand;
      if (0 <= f <= 20) {
        return 1;
      } else if (20 < f <= 40) {
        return 2;
      } else if (40 < f <= 60) {
        return 3;
      } else if (60 < f <= 80) {
        return 4;
      } else {
        return 5;
      }
    },
    hotArray: function () {
      this.array.length = this.hot;
      return this.array;
    },
    lessArray: function () {
      this.lessArrays.length = 5 - this.hot;
      return this.lessArrays;
    },
    cp: function () {
      if (this.isAdd == false) {
        return "收藏";
      } else {
        return "已收藏";
      }
    },
    contrast: function () {
      return "对比";
    },
  },
};
</script>

<style>
/* .search-bgc {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -999;
  background: url("../../assets/img/search/bg_search.png") no-repeat center
    center;
} */
.download-link {
  text-decoration: underline !important;
}
.btn-collect {
  width: 96px;
  height: 30px;
  line-height: 30px;
  color: #4a81fd;
  border: 1px solid #4a81fd;
  cursor: pointer;
}
.btn-collect-had {
  width: 96px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  background-color: #4a81fd;
  cursor: pointer;
}
.star {
  width: 23px;
  height: 22px;
  background: url("../../assets/img/common/star.png") no-repeat center center;
  background-size: 80%;
}
.star-hover {
  width: 23px;
  height: 22px;
  background: url("../../assets/img/common/star_pre.png") no-repeat center center;
  background-size: 80%;
}
.contrast {
  width: 19px;
  height: 18px;
  background: url("../../assets/img/common/plus.png") no-repeat center center;
  background-size: 80%;
}
</style>
